import React from 'react'

import Layout from '../component/layout'
import Image from '../component/image'
import JourneyOmnidya from '../component/journeyOmnidya'
import Link from '../component/link'

const California = () => {
  return(
    <Layout state page="california-header-bg" title="California" captions="New Age. New Insurance.">
      <div className="container-xxl">
        <div className="header-slogan text-center py-5">
          California
        </div>
        <div className="row justify-content-center">
          <div className="col-auto col-lg-8">
            <div className="row no-gutters">
              <div className="col-sm col-md-4">
                <Image src="californiaView.png" />
              </div>
              <div className="col-sm col-md-8 bg-light">
                <div className="py-4 px-4 px-sm-5">
                  <div className="sub-Title mb-2">It's called the Golden State for a reason. </div>
                  <p>Whether you're spending your daily three hours on the 10, hitting up Newport on the weekend, swerving hipsters in Silverlake, or trekking up (or down) to Big Bear...we got you covered. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      
      <div className="california-content-bg mb-5">
        <div className="container-xxl">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="row align-items-center justify-content-between">
                <div className="col-sm-4 order-sm-last mb-5">
                  <Image src="roboMobile.svg" className="img-fluid" />
                </div>
                <div className="col-sm-5 order-sm-first mb-5">
                  <div className="caption mb-3 text-uppercase">Free Gift</div>
                  <div className="header-slogan mb-3">We'll gift you a dashcam</div>  
                  <div>
                    When you join the Omnidya club, we'll send you a dashcam for free. Insights into your journey help us ensure you always have the perfect policy - and help you avoid messy stuff like fraud.
                  </div>
                </div>
              </div>
              <div className="position-relative">
                <Image src="combined-shape.svg" className="decorate-arrow-left" />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row align-items-center justify-content-between text-right text-sm-left">
                <div className="col-sm-4 mb-5">
                  <Image src="roboIphone.svg" className="img-fluid" />
                </div>
                <div className="col-sm-5 mb-5">
                  <div className="caption mb-3 text-uppercase">Download App</div>
                  <div className="header-slogan mb-3">Easily manage your policy in the Omnidya app</div>  
                  <div>
                      Manage every bit of your policy and profile in our handy dandy mobile app. You can find us in the App Store or Google Play.  
                  </div>
                </div>
              </div>
              <div className="position-relative">
                <Image src="combined-shape.svg" className="decorate-arrow-right" />
              </div>
            </div>
            <div className="w-100"></div>
            <div className="col-12 col-lg-8">
              <div className="row align-items-center justify-content-between">
                <div className="col-sm-4 order-sm-last mb-5">
                  <Image src="roboGift.svg" className="img-fluid" />
                </div>
                <div className="col-sm-5 order-sm-first mb-5">
                  <div className="caption mb-3 text-uppercase">
                    Gift Rewards
                  </div>
                  <div className="header-slogan mb-3">Get rewarded for great driving</div>  
                  <div>
                    Great driving deserves to be rewarded.  That's why our premiums are behavior-based. 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 text-center">
            <Link className="btn btn-blue">Sign up and Save</Link>
          </div>
        </div>
      </div>
      <div className="grey-bg">
        <div className="container">
          <div className="px-sm-5 py-5">
            <div className="row justify-content-center">
              <div className="col-auto">
                <Link className="btn btn-outline-secondary text-wrap">Get Omnidya updates</Link>
              </div>
              <div className="col-auto">
                <Link className="btn btn-light text-wrap">Sign up and save</Link>
              </div>
            </div>
          </div>
          <div className="p-sm-5 py-5">
            <div className="row justify-content-center">
              <div className="col-12 col-md-auto col-lg-auto text-center mb-3">
                <Image src="bulb.svg" />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p>
                  Want to keep up with us? 
                </p>
                <ul>
                  <li>Drink way too much cafferine</li>
                  <li>Subscribe to get updates on all the exciting stuff we get up to</li>
                </ul>
                <div className="text-center text-sm-left">
                  <Link className="btn btn-blue">Subscribe</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="py-5 px-sm-5">
          <div className="header-slogan text-center">State Requirements</div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5 col-auto">
            <p>
              To legally operate a vehicle in California, drivers are required to be able to pay for damages and injuries that they cause in car accidents. There are several options to show proof of financial responsibility, including buying liability insurance. California requires insurance companies to provide electronic proof of insurance information to the DMV. 
            </p>
          </div>
          <div className="col-md-6 col-lg-5 col-auto">
            <p>
              You’re required to show proof of insurance or financial responsibility if you’re stopped by a police officer or involved in a car accident, as well as your driver’s license and car registration. You will also need proof of insurance or financial responsibility when you register your car for the first time or renew a registration.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-sm-center py-5 px-lg-5">
          <Link className="btn btn-blue btn-pill">
            Liability Coverage
          </Link>
          <Link className="btn btn-pill">
            Alternactives to car insurance
          </Link>
          <Link className="btn btn-pill">
            Alternactives to home insurance
          </Link>
        </div>
        <div className="py-5 px-lg-5 position-relative">
          <div className="row justify-content-lg-center">
            <div className="col-lg-5 col-auto">
              <div className="sub-Title">Liability Coverage</div>
              <p>
                You are required to carry Liability coverage in California if you’re unable to show any other proof of financial responsibility. If you’re found driving without insurance or proof of financial responsibility, you could face penalties.
              </p>
              <p>
                Liability coverage helps to pay for property damage or bodily injuries that occur from a car accident you cause. It does not cover harm to you or your family members (that’s what Medical Payments are for) or your vehicle (but Collision and Comprehensive coverages do). These additional car insurance coverages can be much cheaper than the serious expenses that can come with a major accident.
              </p>
            </div>
            <div className="col-lg-5 col-auto"></div>
          </div>
          <div className="car d-none d-md-none d-lg-block">
            <Image src="car-1.png" fixed />
          </div>
        </div>
        <div className="py-5 px-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 col-auto mb-5">
              <div className="sub-Title mb-2">Earning the Safe Driver Discount</div>
              <ul className="blue-decoration">
                <li>You have been licensed to drive for the past 3 years.</li>
                <li>During the past 3 years you have not:
                  <ul>
                    <li>Had more than 1 point on your driving record due to a violation(s).</li>
                    <li>Taken traffic school because of a traffic violation more than once.</li>
                    <li>Been the at-fault driver in an accident that resulted in injury or death.</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-lg-5 col-md-6 col-auto mb-5">
              <div className="sub-Title mb-2">What other coverages does Root offer?</div>
              <ul className="blue-decoration">
                <li>Collision</li>
                <li>Comprehensive (including glass coverage)</li>
                <li>Medical Payments</li>
                <li>Uninsured Motorist Property Damage (UMPD)</li>
                <li>Rental Car coverage</li>
                <li>Roadside Assistance</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bluishGrey-bg text-center">
        <div className="container p-3">
          <div className="header-slogan mb-3">
            Average Cost
          </div>
          <div className="row justify-content-center">
            <div className="col-auto col-sm-8 col-lg-5">
              <p>You’re required to show proof of insurance or financial responsibility if you’re stopped by a police officer or involved in a car accident, as well as your driver’s license and car registration. </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bluishGrey-bg pricing-bg text-center">
        <div className="container p-5">
          <div className="row justify-content-center">
            <div className="col-auto col-lg-4">
              <div className="bg-white shadow-sm p-3 m-sm-5">
                <div id="carouselExampleCaptions" className="carousel slide pb-4" data-ride="carousel">
                  <ol className="carousel-indicators">
                    <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="caption mb-3 text-uppercase">
                        Pricing
                      </div>
                      <div className="priceTitle">$599</div>
                      <div className="font-weight-bold">Monthly</div>
                      <p>billed annually</p>
                      <div className="mb-4">You’re required to show proof of insurance or financial responsibility if you’re stopped by a police officer or involved in a car accident, as well as your driver’s license and car registration. </div>
                    </div>
                    <div className="carousel-item">
                      <div className="caption mb-3 text-uppercase">
                        Pricing
                      </div>
                      <div className="priceTitle">$599</div>
                      <div className="font-weight-bold">Monthly</div>
                      <p>billed annually</p>
                      <p>You’re required to show proof of insurance or financial responsibility if you’re stopped by a police officer or involved in a car accident, as well as your driver’s license and car registration. </p>
                    </div>
                    <div className="carousel-item">
                      <div className="caption mb-3 text-uppercase">
                        Pricing
                      </div>
                      <div className="priceTitle">$599</div>
                      <div className="font-weight-bold">Monthly</div>
                      <p>billed annually</p>
                      <p>You’re required to show proof of insurance or financial responsibility if you’re stopped by a police officer or involved in a car accident, as well as your driver’s license and car registration. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="halfGrey pt-5 mb-5">
        <JourneyOmnidya />
      </div>
    </Layout>
  )
}

export default California